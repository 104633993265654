<template>
  <v-snackbar
    elevation="0"
    v-model="snackbar.show"
    variant="tonal"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    @input="handleSnackbarClose"
  >
    <template v-if="snackbar.icon">
      <v-icon left>{{ snackbar.icon }}</v-icon>
    </template>
    {{ snackbar.title }}
    <template v-if="snackbar.description">
      <v-divider class="my-2"></v-divider>
      <div>{{ snackbar.description }}</div>
    </template>
    <template v-slot:actions="{ attrs }">
      <v-btn class="ml-4" v-bind="attrs" @click="closeSnackbar">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import eventBus from '@/services/eventBus';

export default {
  name: 'GlobalSnackbar',
  data() {
    return {
      snackbar: {
        show: false,
        timeout: 5000,
        color: 'success',
        icon: '',
        title: '',
        description: '',
      },
      notificationQueue: [],
      isProcessing: false,
    };
  },
  methods: {
    async showSnackbar({ icon, title, description, color, timeout }) {
      this.notificationQueue.push({ icon, title, description, color, timeout });

      // Process the queue if not already processing
      if (!this.isProcessing) {
        this.processQueue();
      }
    },
    async processQueue() {
      this.isProcessing = true;

      while (this.notificationQueue.length > 0) {
        const currentNotification = this.notificationQueue.shift();
        await this.displayNotification(currentNotification);
      }

      this.isProcessing = false;
    },
    async displayNotification({ icon, title, description, color, timeout }) {
      // Set snackbar properties
      this.snackbar.icon = icon || '';
      this.snackbar.title = title || '';
      this.snackbar.description = description || '';
      this.snackbar.color = color || 'success';
      this.snackbar.timeout = timeout || 5000;

      // Show the snackbar
      this.snackbar.show = true;

      // Wait for the snackbar to close
      await new Promise(resolve => setTimeout(resolve, this.snackbar.timeout + 300));
      this.snackbar.show = false;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
    handleSnackbarClose() {
      this.snackbar.show = false;
    },
  },
  mounted() {
    eventBus.on('show-snackbar', this.showSnackbar);
  },
  beforeUnmount() {
    eventBus.off('show-snackbar', this.showSnackbar);
  },
};
</script>
