<template>
  <DialogBase 
    :isDialogVisible="dialogOpen"
    :dialogWidth="550"
  >
    <template v-slot:header>
      <span>Update Task Status</span>
    </template>

    <template v-slot:content>
      <div class="status-buttons-container">

        <v-tooltip location="top" text="Update task status to 'in progress'">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="tonal"
              class="status-btn"
              color="orange-darken-2"
              @click="updateTaskStatus('In Progress')"
              prepend-icon="mdi-timer-sand" stacked
              :loading="isInProgressTileLoading"
            >
              In Progress
            </v-btn>
          </template>
        </v-tooltip>

        <v-tooltip location="top" text="Update task status to 'completed'">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="tonal"
              class="status-btn"
              color="success"
              @click="updateTaskStatus('Completed')"
              prepend-icon="mdi-check-circle" stacked
              :loading="isCompleteTileLoading"
            >
              Completed
            </v-btn>
          </template>
        </v-tooltip>

        <v-tooltip location="top" text="Update task status to 'archived'">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="tonal"
              class="status-btn"
              color="blue-grey"
              @click="updateTaskStatus('Archived')"
              prepend-icon="mdi-archive" stacked
              :loading="isArchiveTileLoading"
            >
              Archived
            </v-btn>
          </template>  
        </v-tooltip>

      </div>
    </template>

    <template v-slot:footer>
      <v-btn
        text="Cancel"
        variant="text"
        @click="closeDialog"
      ></v-btn>
    </template>
    
  </DialogBase>
</template>

  
<script>
  import api from '@/services/api';
  import DialogBase from './DialogBase.vue';
  import eventBus from '@/services/eventBus';

  export default {
    name: 'TaskConfirmationDialog',
    components: {
      DialogBase
    },
    props: {
      isDialogOpen: {
        type: Boolean,
        default: false
      },
      targetTask: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        isInProgressTileLoading: false,
        isCompleteTileLoading: false,
        isArchiveTileLoading: false,
        currentStatus: '',
        isConfirmBtnLoading: false,
        hasConfirmed: false,
      }
    },
    methods: {
      closeDialog() {
        this.dialogOpen = false;
      },      
      async updateTaskStatus(newStatus) {
        this.showTileBtnLoading(newStatus, true)

        try {
          await api.post(`/UserTask/UpdateUserTaskStatus`, null, {
            params: {
              taskId: this.targetTask.user_Task_ID,
              status: newStatus,
            },
          });

          await new Promise(resolve => setTimeout(resolve, 700));

          this.showSnackbar('success', 'mdi-check-circle', 'Successfully Changed Task Status.', 'Task ' + this.targetTask.user_Task_ID + ' was changed to ' + newStatus);
          eventBus.emit('taskAssigned');
          this.$emit('refresh-tasks')
  
        } catch (error) {
          console.error(error);
          this.showSnackbar('error', 'mdi-alert-circle', 'An Error Occured While Updating Status.', 'Task ' + this.targetTask.user_Task_ID + ' could not be updated.');
        } finally {
          this.showTileBtnLoading(newStatus, false)
          this.closeDialog();
        }
      },
      showTileBtnLoading(status, loadState) {
        if(status === "Completed") {
          this.isCompleteTileLoading = loadState;
        }
        else if(status === "In Progress") {
          this.isInProgressTileLoading = loadState;
        }
        else if(status === "Archived") {
          this.isArchiveTileLoading = loadState;
        }
      },
      showSnackbar(color, icon, title, description) {
        this.$emit('show-snackbar', {
          color,
          icon,
          title,
          description,
        });
      },

    },
    computed: {
      dialogOpen: {
        get() {
          return this.isDialogOpen;
        },
        set(value) {
          this.$emit('update:is-dialog-open', value);
        }
      }
    }
  }
</script>

<style scoped>
.status-buttons-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.status-btn {

  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 100px;
  text-transform: none;
}

.status-btn v-icon {
  font-size: 55px; /* Make the icon larger */
}

.status-btn span {
  font-size: 12px; /* Smaller text under the icon */
  margin-top: 8px;
}

.status-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>