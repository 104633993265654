// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import msalInstance from './services/authConfig'; 
import VueTheMask from 'vue-the-mask';
loadFonts();

async function initializeApp() {
  await msalInstance.initialize();
  createApp(App)
    .use(router)
    .use(vuetify)
    .use(VueTheMask)
    .mount('#app');
}

initializeApp();
