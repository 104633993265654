import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: "96371d7b-fe86-4298-847a-f66ba6ab6fd3",
    authority: "https://login.microsoftonline.com/5f2a68e9-3e1c-4d44-9c19-6c98650dcbbf",
    redirectUri: "https://contacts.gpgusa.com/",
    //redirectUri: "http://localhost:8080"
  }
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;