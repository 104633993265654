<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Data Migration Options</span>
    </template>

    <template v-slot:content>
      <v-tabs class="mb-3" v-model="tab" bg-color="transparent" color="primary" grow>
        <v-tab value="export">Export Contacts</v-tab>
        <v-tab value="import">Import Contacts</v-tab>
      </v-tabs>

      <v-tabs-window v-model="tab">
        <!-- EXPORT SECTION -->
        <v-tabs-window-item value="export">
          <v-card class="ma-0 pa-0">
            <v-card-text class="pt-6">
              <v-row justify="center">
                <v-col cols="10" sm="12">
                  <v-btn
                    height="50"
                    block
                    variant="tonal"
                    color="primary"
                    @click="downloadTemplate"
                  >Download Excel Template</v-btn>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" sm="12">
                  <v-btn height="50" :disabled="true" block variant="tonal" color="purple">Export With Current Filters</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tabs-window-item>

        <!-- IMPORT SECTION -->
        <v-tabs-window-item value="import">
          <v-card class="ma-0 pa-0">
            <v-card-text class="pt-3">
              <v-row>
                <v-col cols="12" class="pt-3">
                  <v-file-input
                    v-model="files"
                    prepend-inner-icon="mdi-file-outline"
                    :prepend-icon="false"
                    :show-size="1000"
                    color="deep-purple-accent-4"
                    label="Select File"
                    placeholder="Select your files"
                    variant="outlined"
                    counter
                    single
                    @change="validateFile"
                  >
                    
                    <template v-slot:selection="{ fileNames }">
                      <template v-for="(fileName, index) in fileNames" :key="fileName">
                        <v-chip
                          v-if="index < 2"
                          class="me-2"
                          color="deep-purple-accent-4"
                          size="small"
                          label
                        >
                          {{ fileName }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline text-grey-darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} File(s)
                        </span>
                      </template>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" class="pt-0 text-right">
                  <v-btn
                    color="primary"
                    variant="tonal"
                    :disabled="files.length < 1 || !valid"
                    :loading="isAddBtnLoading"
                    @click="insertBulkContacts"
                  >Insert Bulk Contacts</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-progress-linear
                    v-if="progress > 0"
                    :model-value="progress"
                    height="10"
                    rounded
                    color="primary"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <span v-if="progress > 0">{{ currentContactIndex }} / {{ totalContacts }} contacts processed</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tabs-window-item>
      </v-tabs-window>  
    </template>

    <template v-slot:footer>
      <v-btn
        variant="text"
        @click="closeDialog"
        :disabled="isAddBtnLoading"
      >Cancel</v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api';
import DialogBase from './DialogBase.vue';
import * as XLSX from 'xlsx';

export default {
  name: 'ContactDataOptionsDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      isAddBtnLoading: false,
      files: [],
      tab: 'export',
      progress: 0,
      currentContactIndex: 0,
      totalContacts: 0,
      successfulContacts: 0
    }
  },
  methods: {
    closeDialog() {
      if (!this.isAddBtnLoading) {
        this.dialogOpen = false;
      }
    },
    validateFile() {
      const file = this.files[0];
      const validExtensions = ['xlsx', 'xls'];
      const fileExtension = file.name.split('.').pop();
      this.valid = validExtensions.includes(fileExtension);
      if (!this.valid) {
        this.showSnackbar('error', 'mdi-alert-circle', 'Invalid File Type', 'Please upload an Excel file.');
      }
    },
    async insertBulkContacts() {
      this.isAddBtnLoading = true;
      this.progress = 0;
      this.currentContactIndex = 0;
      this.successfulContacts = 0;
      try {
        const file = this.files[0];
        const data = await this.readExcelFile(file);
        this.totalContacts = data.length;

        for (let i = 0; i < this.totalContacts; i++) {
          try {
            await this.sendDataToAPI(data[i]);
            this.successfulContacts++;
          } catch (error) {
            console.error(`Error uploading contact ${i + 1}:`, error);
          }
          await new Promise(resolve => setTimeout(resolve, 300));
          this.currentContactIndex = i + 1;
          this.progress = (this.currentContactIndex / this.totalContacts) * 100;
        }

        this.showSnackbar('success', 'mdi-check-circle', `Contacts Uploaded`, `Successfully added ${this.successfulContacts}/${this.totalContacts} contacts.`);
        this.refreshParentCollection();
        this.clearFile();
        this.closeDialog();
      } catch (error) {
        this.showSnackbar('error', 'mdi-alert-circle', 'Upload Failed', 'Failed to upload contacts. Please try again.');
      } finally {
        this.isAddBtnLoading = false;
        this.progress = 0;
      }
    },
    readExcelFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);
          resolve(json);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });
    },
    async sendDataToAPI(contact) {
      const formattedContact = {
        First_Name: String(contact.First_Name || ''),
        Last_Name: String(contact.Last_Name || ''),
        Title: String(contact.Title || ''),
        Company_Name: String(contact.Company_Name || ''),
        Phone_Primary: String(contact.Phone_Primary || ''),
        Phone_Direct: String(contact.Phone_Direct || ''),
        Phone_Mobile: String(contact.Phone_Mobile || ''),
        Email: String(contact.Email || ''),
        Address: String(contact.Address || ''),
        City: String(contact.City || ''),
        State: String(contact.State || ''),
        Zip_Code: String(contact.Zip_Code || '')
      };
      await api.post('/Contact/AddContact', formattedContact);
    },
    downloadTemplate() {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([
        {
          First_Name: '', Last_Name: '', Title: '', Company_Name: '',
          Phone_Primary: '', Phone_Direct: '', Phone_Mobile: '',
          Email: '', Address: '', City: '', State: '', Zip_Code: ''
        }
      ], {
        header: ["First_Name", "Last_Name", "Title", "Company_Name", "Phone_Primary", "Phone_Direct", "Phone_Mobile", "Email", "Address", "City", "State", "Zip_Code"]
      });
      XLSX.utils.book_append_sheet(wb, ws, 'Template');
      XLSX.writeFile(wb, 'ContactsTemplate.xlsx');
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', { color, icon, title, description });
    },
    refreshParentCollection() {
      this.$emit('refresh-contacts');
    },
    clearFile() {
      this.files = [];
    }
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:is-dialog-open', value);
      }
    }
  }
}
</script>

<style scoped>
/* Add any custom styles here */
</style>
