<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Contact Address Information</span>
    </template>

    <template v-slot:actions>
      <v-chip 
      class="ma-1 mr-2" 
      variant="tonal" 
      color="primary"
      label>
        <v-icon icon="mdi-label-outline" start></v-icon>
        {{ targetContact.contact_ID }}
      </v-chip>
    </template>

    <template v-slot:content>
      <v-row>
        <v-col cols="12" class="pt-4 pb-0">
          <p><strong>Address Information</strong></p>
        </v-col>

        <v-col cols="12">
          <v-card class="py-4" variant="flat" border="gray thin" elevation="0">
            <v-row v-for="(field, index) in fields" :key="index" class="px-4">
              <v-col class="text-left" cols="6">
                <strong>{{ field.label }}:</strong>
              </v-col>
              <v-col class="text-right" cols="6">
                {{ targetContact[field.key] === null  || targetContact[field.key] === "" ? 'unknown' : targetContact[field.key] }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" class="pt-0" v-if="targetContact.address">
          <p><strong>Actions</strong></p>
        </v-col>

        <v-col cols="12" class="pt-0" v-if="targetContact.address">
          <v-alert
            color="primary"
            density="compact"
            variant="tonal"
            transition="slide-x-transition"
          >
            <v-row align="center" no-gutters>
              <v-col>
                <v-icon color="primary" class="mr-2">mdi-check</v-icon>
                Address Found
              </v-col>
              <v-col class="text-right">
                <v-btn
                  size="small"
                  variant="tonal"
                  prepend-icon="mdi-open-in-new"
                  text="Open"
                  @click="openGoogleMaps"
                ></v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>

      </v-row>
    </template>

    <template v-slot:footer>
      <v-btn text="Close" variant="text" @click="closeDialog"></v-btn>
    </template>
  </DialogBase>
</template>

<script>
import DialogBase from './DialogBase.vue';

export default {
  name: 'ContactAddressDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    targetContact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fields: [
        { label: 'Company Name', key: 'company_Name' },
        { label: 'Address', key: 'address' },
        { label: 'State', key: 'state' },
        { label: 'City', key: 'city' },
        { label: 'Zip Code', key: 'zip_Code' }
      ]
    };
  },
  methods: {
    closeDialog() {
      this.dialogOpen = false;
    },
    openGoogleMaps() {
      const address = encodeURIComponent(`${this.targetContact.address}, ${this.targetContact.state}`);
      const url = `https://www.google.com/maps/search/?api=1&query=${address}`;
      window.open(url, '_blank');
    },
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:is-dialog-open', value);
      }
    }
  }
};
</script>
