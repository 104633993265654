<template>
  <div>
    <view-container :viewHeader="'Task Hierarchy'">
      <v-container class="pa-0">
        <v-row>
          <v-col cols="12">
            <v-card border="gray thin" elevation="0" class="rounded-sm" min-width="200">
              <div class="pa-2 d-flex align-center justify-space-between">
                <v-btn-group variant="outlined" density="compact" divided>
                  <v-btn size="small" @click="changeViewMode('Quarter Day')">Quarter Day</v-btn>
                  <v-btn size="small" @click="changeViewMode('Half Day')">Half Day</v-btn>
                  <v-btn size="small" @click="changeViewMode('Day')">Day</v-btn>
                  <v-btn size="small" @click="changeViewMode('Week')">Week</v-btn>
                  <v-btn size="small" @click="changeViewMode('Month')">Month</v-btn>
                </v-btn-group>
                <div class="d-flex">
                  <v-btn class="mx-1" border="gray thin" variant="flat" prepend-icon="mdi-plus">Add Task</v-btn>
                  <v-btn class="mx-1" border="gray thin" variant="flat" prepend-icon="mdi-content-save">Save Changes</v-btn>
                </div>
              </div>
              <!-- Gantt Chart container with fixed height and scrollable overflow -->
              <div class="gantt-style pa-2" id="gantt_chart"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </view-container>
  </div>
</template>

<script>
import ViewContainer from '../layouts/ViewContainer';
import api from '@/services/api'; // Ensure this path is correct

export default {
  name: 'TaskHierarchyView',
  components: {
    ViewContainer,
  },
  data: () => ({
    gantt: null, // Reference to the Gantt chart instance
    tasks: [], // Store fetched tasks here
  }),
  async mounted() {
    const id = this.$route.params.id;
    console.log('Task ID:', id);

    await this.loadFrappeGantt();
    await this.fetchTasks(id);
    this.drawGanttChart('Day');
  },
  methods: {
    async fetchTasks(taskId) {
      try {
        // Use the API call with the `id` parameter
        const response = await api.get(`/UserTask/GetTaskTreeByTaskId?taskId=${taskId}`)
        
        this.tasks = response.data.map(task => ({
          id: task.user_Task_ID,
          name: task.description,
          start: task.open_Date,
          end: task.due_Date,
          progress: 100,
          dependencies: task.task_Dependencies,
          custom_class: 'cc-task',
        }));
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    },
    loadFrappeGantt() {
      return new Promise((resolve) => {
        if (typeof window.Gantt !== 'undefined') {
          resolve(); // If already loaded
        } else {
          const script = document.createElement('script');
          script.src = 'https://cdn.jsdelivr.net/npm/frappe-gantt/dist/frappe-gantt.umd.js';
          script.onload = () => {
            console.log('Frappe Gantt JavaScript loaded');
            resolve();
          };
          document.head.appendChild(script);

          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = 'https://cdn.jsdelivr.net/npm/frappe-gantt/dist/frappe-gantt.css';
          link.onload = () => {
            console.log('Frappe Gantt CSS loaded');
          };
          document.head.appendChild(link);
        }
      });
    },
    drawGanttChart(viewMode) {
      const tasks = this.tasks;
      if (typeof window.Gantt !== 'undefined') {
        if (this.gantt) {
          this.gantt.refresh(tasks);
        } else {
          this.gantt = new window.Gantt("#gantt_chart", tasks, {
            view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
            view_mode: viewMode,
            custom_popup_html: function(task) {
              const completedIcon = task.progress === 100 ? '✅' : '';
              return `
                <div class="details-container">
                  <h5>${task.name} ${completedIcon}</h5>
                  <p>Start: ${task.start}</p>
                  <p>End: ${task.end}</p>
                  <p>Progress: ${task.progress}%</p>
                </div>
              `;
            }
          });
        }
      } else {
        console.error('Gantt chart library is not loaded.');
      }
    },
    changeViewMode(viewMode) {
      if (this.gantt) {
        this.gantt.change_view_mode(viewMode);
      } else {
        console.error('Gantt chart instance is not available.');
      }
    },
  }
};
</script>

<style>
.cc-task g rect {
  fill: rgba(188, 188, 188, 0.3) !important;
  stroke: black !important;
  stroke-width: 0.2;
}
.cc-task .bar-wrapper .bar {
  stroke: rgb(0, 174, 255) !important;
  fill: black;
}
.cc-task .bar-progress {
  fill: rgba(208, 194, 255, 0.7) !important;
}
.gantt-style .gantt-container {
  height: 850px;
}
</style>
