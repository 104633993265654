<template>
  <v-breadcrumbs class="pa-0 text-text text-h6" :items="breadcrumbItems">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :disabled="item.disabled" :to="item.to">
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
      
<script>

import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'BreadCrumbs',
  setup() {
    const route = useRoute();

    //just defaults to dashboard for now
    const breadcrumbItems = computed(() => {
      const pathSegments = route.path.split('/').filter(p => p.length > 0);

      return pathSegments.map((segment, index, array) => {

        var pathToSegment = '/' + array.slice(0, index + 1).join('/');
        console.log(pathToSegment)

        return {
          text: segment.replace(/-/g, ' ').replace(/^\w/, c => c.toUpperCase()), // Format the text
          to: '/views/dashboard',
          disabled: index === array.length - 1 
        };
      });
    });

    return {
      breadcrumbItems
    };
  }
}
</script>
