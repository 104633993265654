<template>
  <div class="d-flex justify-center align-center" style="height: 100vh;">
    <v-col v-if="!isAzureLoginLoading">

      <v-row class="justify-center align-center">
        <v-img max-width="160" src="@/assets/app/GPG Logo Large Black Text.png"></v-img>
      </v-row>

      <v-row class="text-h5 mt-6 justify-center align-center">
        <p>Global Precision Group</p>
      </v-row>

      <v-row class="text-subtitle-1 justify-center align-center">
        <p>Task Manager</p>
      </v-row>

      <v-row class="mt-12 justify-center align-center">
        <v-btn
          :loading="isAzureLoginLoading"
          class="mb-2"
          height="54"
          color="primary"
          variant="tonal"
          size="large"
          @click="validateWithAzure"
        >
          <v-icon
            right 
            dark
            class="ml-0 mr-4"
          >
            mdi-login 
          </v-icon>

          Login with Azure
        </v-btn>
      </v-row>
    </v-col>

    <v-col v-else>
      <v-row class="justify-center align-center">
        <v-progress-circular indeterminate="true" color="primary" :width="4" size="60"></v-progress-circular>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { useUserState } from '@/services/userStore';
import { useTheme } from 'vuetify';
import { ref, onMounted } from 'vue';
import api from '@/services/api';
import msalInstance from '@/services/authConfig';
import { useRouter } from 'vue-router';

export default {
  name: 'SplashView',

  setup() {
    const userState = useUserState();
    const theme = useTheme();
    const router = useRouter();
    const isAzureLoginLoading = ref(false);

    onMounted(() => {
      const savedDarkMode = localStorage.getItem('darkMode');
      if (savedDarkMode !== null) {
        theme.global.name.value = JSON.parse(savedDarkMode) ? 'dark' : 'light';
      }
    });

    async function validateWithAzure() {
      if (isAzureLoginLoading.value) return;

      isAzureLoginLoading.value = true;
      const token = await getMSALToken();
      if (token) {
        console.log('Msal token was successfully received');

        localStorage.setItem('token', token);

        const isValid = await validateToken(token);
        if (isValid) {
          const userProfile = await getUserProfile();
          if (userProfile) {
            console.log('User Profile:', userProfile);
            userState.setUserProfile(userProfile);
            router.push('/views/dashboard');
          } else {
            console.log('Failed to get user profile');
          }
        } else {
          console.log('Token validation failed');
        }
      } else {
        console.log('MSAL Token was Invalid');
      }
      isAzureLoginLoading.value = false;
    }

    async function getUserProfile() {
      try {
        const response = await api.get('/authentication/GetUser');
        if (response.status === 200) {
          return response.data;
        } else {
          console.error('Failed to get user');
          return null;
        }
      } catch (error) {
        console.error('Error while getting user', error);
        return null;
      }
    }

    async function getMSALToken() {
      const loginRequest = {
        scopes: ['api://96371d7b-fe86-4298-847a-f66ba6ab6fd3/access_as_user', 'User.Read'],
        prompt: "select_account"
      };

      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
          try {
            const silentToken = await msalInstance.acquireTokenSilent(loginRequest);
            return silentToken.accessToken;
          } catch (silentError) {
            console.log('Silent token acquisition failed, falling back to interactive method.');
            if (silentError.errorCode === 'interaction_required') {
              const loginResponse = await msalInstance.loginPopup(loginRequest);
              return loginResponse.accessToken;
            } else {
              throw silentError;
            }
          }
        } else {
          const loginResponse = await msalInstance.loginPopup(loginRequest);
          return loginResponse.accessToken;
        }
      } catch (error) {
        console.error('Error during Azure MSAL authentication:', error);
        return null;
      }
    }

    async function validateToken(accessToken) {
      try {
        const response = await api.get('/authentication/ValidateViaAzure', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 200) {
          userState.state.hasAuthenticated = true;
          return true;
        } else {
          userState.state.hasAuthenticated = false;
          return false;
        }
      } catch (error) {
        console.error('Error during token validation:', error);
        userState.state.hasAuthenticated = false;
        return false;
      }
    }

    return {
      validateWithAzure,
      isAzureLoginLoading,
    };
  },
};

</script>
