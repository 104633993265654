<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>{{ targetContact.full_Name }}</span>
    </template>

    <template v-slot:actions>
      <v-chip class="ma-1 mr-2" variant="tonal" color="primary" label>
        <v-icon icon="mdi-label-outline" start></v-icon>
        {{ targetContact.contact_ID }}
      </v-chip>
    </template>

    <template v-slot:content>
      <v-tabs class="mb-3" v-model="tab" bg-color="transparent" color="primary" grow>
        <v-tab value="view-tasks">View Tasks</v-tab>
        <v-tab value="task-options">Task Options</v-tab>
      </v-tabs>

      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="view-tasks">
          <v-card class="ma-0 pa-0">
            
            <div v-if="tasks.length === 0">
              <v-row class="justify-center text-center mt-8">
                This contact doesnt contain any related tasks.
              </v-row>
              <v-row class="justify-center mt-8">
                <v-btn variant="tonal" color="primary" @click="tab = 'task-options'">Add Task</v-btn>
              </v-row>
            </div>

            <v-card-text class="pt-0">
              <v-progress-linear indeterminate="true" color="primary" v-if="isCompletingTaskLoading"></v-progress-linear>
              <v-list-subheader v-if="tasks.length !== 0">Action Required</v-list-subheader>
              <v-list class="ma-0 py-0 px-2" max-height="240">
                <v-list-item class="px-0" v-for="task in tasks" :key="task.user_Task_ID">
                  <v-alert
                    color="primary"
                    density="comfortable"
                    border="start"
                    border-color="blue accent-4"
                    variant="tonal"
                    transition="slide-x-transition"
                  >
                    <v-row align="center" no-gutters>
                      <v-col class="align-center text-no-wrap">
                        <v-icon color="primary" class="mr-1">{{ task.type_Icon }}</v-icon>
                        {{ task.type }}
                      </v-col>
                      <v-col class="text-right">
                        <v-btn
                          class="mr-2"
                          size="small"
                          density="comfortable"
                          icon="mdi-help"
                          variant="tonal"
                          color="primary"
                          @click="toggleTaskInformationDialog(task)"
                        ></v-btn>
                        <v-btn
                          size="small"
                          density="comfortable"
                          icon="mdi-check"
                          variant="tonal"
                          color="primary"
                          @click="completeTask(task.contact_Task_ID)"
                          text="Mark Completed"
                        ></v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tabs-window-item>

        <v-tabs-window-item value="task-options">
          <v-card class="ma-0 pa-0">
            <v-card-text>
              <!-- New Task and Existing Task Buttons -->
              <div v-if="!showNewTaskForm && !showExistingTaskForm">
                <v-btn
                  variant="tonal"
                  color="primary"
                  class="mb-2"
                  block
                  @click="showNewTaskForm = true; showExistingTaskForm = false"
                >
                  Create New Task
                </v-btn>
                <v-btn
                  variant="tonal"
                  color="primary"
                  block
                  @click="showNewTaskForm = false; showExistingTaskForm = true"
                >
                  Add To Existing Task
                </v-btn>
              </div>

              <!-- New Task Form -->
              <v-form v-if="showNewTaskForm" ref="addTaskForm">
                <v-select
                  :items="taskTypes"
                  v-model="newTask.type_ID"
                  item-title="type"
                  item-value="type_ID"
                  variant="outlined"
                  density="compact"
                  label="Task Type"
                  :rules="[v => !!v || 'Task type is required']"
                ></v-select>
                <v-textarea
                  class="pb-2"
                  variant="outlined"
                  density="compact"
                  v-model="newTask.description"
                  label="Task Description"
                  :rules="[v => !!v || 'Description is required']"
                ></v-textarea>
                <v-text-field
                  variant="outlined"
                  density="compact"
                  v-model="newTask.due_Date"
                  label="Due Date"
                  type="date"
                  :rules="[v => !!v || 'Due Date is required']"
                ></v-text-field>
                <v-btn
                  color="primary"
                  :variant="isAddBtnDisabled ? 'outlined' : 'tonal'"
                  :disabled="isAddBtnDisabled"
                  :loading="isAddBtnLoading"
                  @click="addTask"
                  block
                >
                  Add Task For {{ targetContact.full_Name }}
                </v-btn>
              </v-form>

              <!-- Existing Task Selection -->
              <div v-if="showExistingTaskForm">
                <v-autocomplete
                  prepend-inner-icon="mdi-magnify"
                  :items="openUserTasks"
                  item-title="description"
                  item-value="user_Task_ID"
                  label="Select Existing Task"
                  v-model="selectedTask"
                  variant="outlined"
                  density="compact"
                >
                  <template v-slot:item="{ props, item }">
                    <v-list-item v-bind="props">
                      <template v-slot:prepend>
                        <v-avatar variant="tonal" size="36" color="primary">
                          <v-icon size="16">{{ item.raw.type_Icon }}</v-icon>
                        </v-avatar>
                      </template>

                      <template v-slot:title>
                        <div>
                          {{ truncateDescription(item.raw.description, this.$vuetify.display.mobile ? 24 : 40 ) }}
                        </div>
                      </template>

                      <template v-slot:subtitle>
                        <div>
                          {{ item.raw.type }}
                        </div>
                      </template>

                      <template v-slot:append>
                        <v-btn
                          class="ml-2"
                          size="small"
                          density="comfortable"
                          icon="mdi-help"
                          variant="tonal"
                          color="primary"
                          @click="toggleTaskInformationDialog(item.raw)"
                        ></v-btn>
                      </template>
                    </v-list-item>
                  </template>
                </v-autocomplete>


                <v-btn
                  block
                  :loading="isAddBtnLoading"
                  color="primary"
                  variant="tonal"
                  :disabled="!selectedTask"
                  @click="viewTaskDetails(selectedTask)"
                >
                  Add {{ targetContact.full_Name }} To Task
                </v-btn>
              </div>
            </v-card-text>

          </v-card>
        </v-tabs-window-item>
      </v-tabs-window>
    </template>

    <template v-slot:footer>
      <v-btn text="Cancel" variant="text" @click="closeDialog">Cancel</v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api';
import DialogBase from './DialogBase.vue';

export default {
  name: 'ContactTasksDialog',
  components: {
    DialogBase,
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    targetContact: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tasks: [],
      taskTypes: [],
      openUserTasks: [],
      isCompletingTaskLoading: false,
      isTasksLoading: true,
      isDetailedTaskDialogActive: false,
      tab: 'view-tasks',
      showNewTaskForm: false,
      showExistingTaskForm: false,
      newTask: {
        type_ID: null,
        description: '',
        due_Date: null,
      },
      selectedTask: null,
      isAddBtnLoading: false,
    };
  },
  mounted() {
    this.getContactTasks();
    this.getOpenUserTasks();
    this.getTaskTypes();
  },
  computed: {
    isAddBtnDisabled() {
      return !this.newTask.type_ID || this.newTask.description.length < 3;
    },
    dialogOpen: {
      get() {
        this.getContactTasks();
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:is-dialog-open', value);
      },
    },
  },
  watch: {
    tab() {
      // Reset form visibility when the tab changes
      this.resetTaskForms();
    },
  },
  methods: {
    async getContactTasks() {
      try {
        this.isTasksLoading = true;
        console.log(this.targetContact.contact_ID)
        const response = await api.get(`/Contact/GetOpenTasksRelatedToContactById?contactId=${this.targetContact.contact_ID}`);
        if (response.status === 200) {
          console.log(response.data)
          this.tasks = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      this.isTasksLoading = false;
    },
    async getTaskTypes() {
      try {
        const response = await api.get(`/TaskType/GetTaskTypes`);
        if (response.status === 200) {
          this.taskTypes = response.data;
        } else {
          console.error('Failed to load task types');
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getOpenUserTasks() {
      try {
        const response = await api.get(`/UserTask/GetAllOpenUserTasks`);
        if (response.status === 200) {
          this.openUserTasks = response.data;
        } else {
          console.error('Failed to load task types');
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addTask() {
      this.isAddBtnLoading = true;
      const userTask = {
        Type_ID: this.newTask.type_ID,
        Description: this.newTask.description,
        Due_Date: this.newTask.due_Date, 
        Contact_ID: this.targetContact.contact_ID,
      }; 
      try {
        await new Promise(resolve => setTimeout(resolve, 500));
        await api.post('/Contact/AddTaskWithContact', userTask);
        this.showSnackbar('success', 'mdi-check-circle', 'Task Created!', 'New task created for ' + this.targetContact.full_Name);
        this.$emit('refresh-tasks');
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Error', 'Failed to create task.');
      } finally {
        this.isAddBtnLoading = false;
      }
    },
    async assignTaskToContact() {
      this.isAddBtnLoading = true;
      const payload = {
        Contact_ID: this.targetContact.contact_ID,
        Task_ID: this.selectedTask.user_Task_ID,
      };
      try {
        await new Promise(resolve => setTimeout(resolve, 500));
        await api.post('/Contact/AddContactToTask', payload);
        this.showSnackbar('success', 'mdi-check-circle', 'Task and Contact Were Linked!', 'Task was assigned to  ' + this.targetContact.full_Name);
        this.$emit('refresh-tasks');
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Error', 'Failed to create task.');
      } finally {
        this.isAddBtnLoading = false;
      }
    }, 
    truncateDescription(text, maxLength) {
      if (!text || text.length <= maxLength) {
        return text;
      }
      return text.slice(0, maxLength) + '...';
    },
    viewTaskDetails(taskId) {
      console.log(taskId);
      // View Task Details Logic
    }, 
    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.newTask.description = '';
      this.newTask.type_ID = null;
      this.newTask.due_Date = null;
      this.selectedTask = null;
      this.searchTask = '';
      this.step = 1; 
    },
    closeDialog() {
      this.tab = 'view-tasks';
      this.dialogOpen = false;
      this.tasks = [];
    },
    resetTaskForms() {
      // Reset form visibility and show buttons when the tab is switched
      this.showNewTaskForm = false;
      this.showExistingTaskForm = false;
    },
    toggleTaskInformationDialog(task) {
      this.$emit('show-task-info-dialog', task);
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000,
      });
    },
  },
};
</script>

