<template>
  <div class="d-flex justify-center align-center" style="height: 100vh;">
    <v-col>
      <v-row class="justify-center align-center">
        <v-img max-width="100" src="@/assets/app/denied.svg"></v-img>
      </v-row>

      <v-row class="text-h5 mt-8 justify-center align-center">
        <p>Switch to an account that has permission</p>
      </v-row>

      <v-row class="body-2 mt-6 justify-center align-center">
        <p>Your account doesn’t have permission to view or manage this page.</p>
      </v-row>
      <v-row class="body-2 justify-center align-center">
        <p>Please check your credentials and try again.</p>
      </v-row>

      <v-row class="mt-6 justify-center align-center">
        <v-btn 
        color="primary" 
        elevation="0" 
        class="mt-2"
        @click="retryNavigation"
        variant="tonal"
        outlined
        >
          <v-icon
            right 
            dark
            class="ml-0 mr-2"
          >
            mdi-reload-alert 
          </v-icon>
        Retry</v-btn>
      </v-row>
    </v-col>
  </div>
</template>

<script>

export default {
  methods: {
    retryNavigation() {
      this.$router.push("/");
    }
  }
}
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}
</style>
