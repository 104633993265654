<template>
  <v-row class="pa-2" dense>
    <!-- Space on left -->
    <v-col cols="12" class="py-0 mb-0" md="1"></v-col>

    <v-col cols="12" md="12">
      <v-card class="mb-2 mt-0 pt-0 rounded-sm" border="gray thin" elevation="0" >
        <v-row class="pl-3 py-3 text-center align-center justify-center">
          <bread-crumbs class="pl-3 py-3"></bread-crumbs>
          <v-spacer></v-spacer>
          <p class="align-center justify-center text-center text-grey-lighten-1">V1.0.0</p>

          <v-list-item value="theme" class="ml-2 px-2" @click="toggleTheme">
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-list-item>

          <v-list-item value="settings" class="ml-1 px-2 mr-5" @click="settingsBtnClicked">
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item>
        </v-row>
      </v-card>

      <p class="text-h5 mt-1">
        <!--{{this.viewHeader}}-->
      </p>
      <slot></slot>
    </v-col>

    <!-- Space on right -->
    <v-col cols="12" md="1"></v-col>
  </v-row>
</template>

<script>
import BreadCrumbs from '../../components/navigation/BreadCrumbs';
import VueCookies from 'vue-cookies';

export default {
  name: 'ViewContainer',
  components: {
    BreadCrumbs,
  },
  props: {
    viewHeader: String,
  },
  data() {
    return {
      drawer: true,
      rail: false,
      notifications: [],
    };
  },
  methods: {
    settingsBtnClicked() {
      this.$router.push('/views/settings');
    },
    toggleTheme() {
      const currentTheme = VueCookies.get('theme') || 'light';
      const newTheme = currentTheme === 'light' ? 'dark' : 'light';
      this.$vuetify.theme.global.name = newTheme;
      VueCookies.set('theme', newTheme, '7d');
    },
    setInitialTheme() {
      const savedTheme = VueCookies.get('theme') || 'light';
      this.$vuetify.theme.global.name = savedTheme;
    },
  },
  mounted() {
    this.setInitialTheme();
  },
};
</script>
