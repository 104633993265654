<!-- App.vue -->
<template>
  <v-app>
    <GlobalSnackbar />

    <TimeoutDialog
      v-if="isTimeoutDialogActive"
      :is-dialog-open="isTimeoutDialogActive"
      @update:is-dialog-open="isTimeoutDialogActive = $event"
    />

    <router-view v-slot="{ Component, route }">
      <span v-if="!route.meta.hideNav">
        <NavigationPanel
          :uncompletedTaskCount="uncompletedTaskCount"
          @logUserOut="logUserOut"
          @toggleTheme="toggleTheme"
        ></NavigationPanel>
      </span>

      <v-main>
        <v-sheet class="bg-lightBackground full-background">
          <keep-alive :exclude="['DashboardView', 'UserTasksView', 'TaskHierarchyView']">
            <component :is="Component" @toggleTheme="toggleTheme"></component>
          </keep-alive>
        </v-sheet>
      </v-main>
    </router-view>
  </v-app>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/services/api';
import msalInstance from './services/authConfig';
import NavigationPanel from './components/navigation/NavigationPanel';
import GlobalSnackbar from '../src/views/layouts/GlobalSnackBar.vue';
import { useUserState } from '@/services/userStore';
import { useTheme } from 'vuetify';
import emitter from '@/services/eventBus';
import TimeoutDialog from '@/components/dialogs/TimeoutDialog.vue';

const theme = useTheme();
const userState = useUserState();
const router = useRouter();
const hasAuthenticated = ref(false);
const isTimeoutDialogActive = ref(false)
const isSiteInMaintenance = ref(false)

const isDarkMode = ref(theme.global.current.value.dark);
const isLoggingOut = ref(false);
const uncompletedTaskCount = ref(0);

// Function to toggle the theme
function toggleTheme() {
  isDarkMode.value = !isDarkMode.value;
  theme.global.name.value = isDarkMode.value ? 'dark' : 'light';
  localStorage.setItem('darkMode', JSON.stringify(isDarkMode.value));
}

// Function to fetch the uncompleted task count
async function fetchUncompletedTaskCount() {
  try {
    const response = await api.get('/dashboard/GetUncompletedTasksCountByEmployeeId');
    if (response) {
      uncompletedTaskCount.value = response.data;
    } else {
      console.error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching uncompleted task count:', error);
  }
}

// Function to validate the token
async function validateToken(token) {
  try {
    const response = await api.get('/authentication/ValidateViaAzure', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      userState.state.hasAuthenticated = true;
      const userProfile = await getUserProfile();
      if (userProfile) {
        userState.setUserProfile(userProfile);
        console.log(userProfile)
        hasAuthenticated.value = true;
      }
    } else {
      userState.state.hasAuthenticated = false;
      router.push({ path: '/' });
    }
  } catch (error) {
    console.error('Error during token validation:', error);
    userState.state.hasAuthenticated = false;
    router.push({ path: '/' });
  }
}

// Function to get the user profile
async function getUserProfile() {
  try {
    const response = await api.get('/authentication/GetUser');
    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Failed to get user');
      return null;
    }
  } catch (error) {
    console.error('Error while getting user', error);
    return null;
  }
}

// Function to log the user out
function logUserOut() {
  isLoggingOut.value = true;
  msalInstance.logoutPopup().then(() => {
    userState.clearUserProfile();
    localStorage.removeItem('token');
    router.push({ name: 'Login' });
    setTimeout(() => {
      isLoggingOut.value = false;
    }, 1000);
  }).catch(error => {
    console.error('Error during logout:', error);
  });
}

function showTimeoutDialog() {
  isTimeoutDialogActive.value = !isTimeoutDialogActive.value;
}

// Fetch the task count and listen for events on mount
onMounted(() => {
  if(isSiteInMaintenance.value) {
    router.push({ path: '/under-construction' });
  }

  emitter.on('taskAssigned', fetchUncompletedTaskCount);
  emitter.on('invokeTimeout', showTimeoutDialog);
  fetchUncompletedTaskCount();

  const savedDarkMode = localStorage.getItem('darkMode');
  if (savedDarkMode !== null) {
    isDarkMode.value = JSON.parse(savedDarkMode);
    theme.global.name.value = isDarkMode.value ? 'dark' : 'light';
  }

  const token = localStorage.getItem('token');
  if (token && !isLoggingOut.value) {
    validateToken(token);
  }
});
</script>

<style scoped>
.full-background {
  min-height: 100vh;
}
</style>
