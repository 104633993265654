import axios from 'axios';
const isProduction = true;
const baseURL = isProduction ? 'https://contactmanagementapi-gceegyfvchdcewcg.centralus-01.azurewebsites.net/api' : 'http://localhost:5252/api';

//Axios instance
const instance = axios.create({
  baseURL: baseURL,
  timeout: 15000,
});

//refresh the users token
async function refreshToken() {
  try {
    const response = await axios.post(`${baseURL}/authentication/refresh-token`, {
      token: localStorage.getItem('refreshToken'),
    });
    const { token, refreshToken } = response.data;
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    return token;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error;
  }
}

//request interceptor to include the token in all requests
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

//response interceptor to handle token refresh
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log('intercepted')
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      try {
        const newToken = await refreshToken();
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return instance(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);

        // Show the TimeoutDialog and refresh the page
        // eventBus.emit('invokeTimeout');
      }
    }

    if (error.response && error.response.status === 429) {
      console.error('Too Many Server Requests.. wait a sec.');
    }

    return Promise.reject(error);
  }
);


export default instance;
