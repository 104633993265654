<template>
  <DialogBase 
    :isDialogVisible="dialogOpen"
    :dialogWidth="450"
  >
  
    <template v-slot:header>
      <span><v-icon class="pr-3">mdi-timer-outline</v-icon>Your session has expired.</span>
    </template>

    <template v-slot:content>
      <p>Please refresh the page to continue using this application. This makes the application more secure.</p>
    </template>

    <template v-slot:footer>
      <v-btn
        :loading="isConfirmBtnLoading"
        text="Refresh"
        append-icon="mdi-refresh"
        color="primary"
        variant="tonal"
        @click="refreshPage"
      ></v-btn>
    </template>
  </DialogBase>
</template>
  
<script>

import DialogBase from './DialogBase.vue';

  export default {
    name: 'TaskConfirmationDialog',
    components: {
      DialogBase
    },
    props: {
      isDialogOpen: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        hasConfirmed: false,
        isConfirmBtnLoading: false,
      }
    },
    methods: {
      closeDialog() {
        this.dialogOpen = false;
      },
      async refreshPage() {
        this.$router.go(0);
      },
      showSnackbar(color, icon, title, description) {
        this.$emit('show-snackbar', {
          color,
          icon,
          title,
          description,
        });
      },
    },
    computed: {
      dialogOpen: {
        get() {
          return this.isDialogOpen;
        },
        set(value) {
          this.$emit('update:is-dialog-open', value);
        }
      }
    }
  }
</script>
