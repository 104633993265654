// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import SplashView from '@/views/pages/SplashView.vue';
import Dashboard from '@/views/pages/DashboardView.vue';
import Contacts from '@/views/pages/ContactsView.vue';
import TaskHistory from '@/views/pages/TasksView.vue';
import SettingsView from '@/views/pages/SettingsView.vue';
import CalendarView from '@/views/pages/CalendarView.vue';
import NotAuthorizedView from '@/views/pages/NotAuthorizedView.vue';
import TaskHierarchyView from '@/views/pages/TaskHierarchyView.vue';
import UnderConstructionView from '@/views/pages/UnderConstructionView.vue';
import EmployeesView from '@/views/pages/EmployeesView.vue';
import UserTasksView from '@/views/pages/UserTasksView.vue';
import FanucLadderProcessor from '@/views/pages/FanucLadderProcessor.vue';

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: SplashView,
    meta: { requiresAuth: false, hideNav: true },
  },
  {
    path: '/not-authorized',
    name: 'notAuthorized',
    component: NotAuthorizedView,
    meta: { requiresAuth: false, hideNav: true },
  },
  {
    path: '/under-construction',
    name: 'underConstruction',
    component: UnderConstructionView,
    meta: { requiresAuth: false, hideNav: true },
  },
  {
    path: '/views/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/views/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: { requiresAuth: true },
  },
  {
    path: '/views/employees',
    name: 'Employees',
    component: EmployeesView,
    meta: { requiresAuth: true },
  },
  {
    path: '/views/task-management',
    name: 'TaskManagement',
    component: TaskHistory,
    meta: { requiresAuth: true },
  },
  {
    path: '/views/task-management/task-tree/:id',
    name: 'TaskHierarchy',
    component: TaskHierarchyView,
    meta: { requiresAuth: true },
  },
  {
    path: '/views/my-tasks',
    name: 'MyTasks',
    component: UserTasksView,
    meta: { requiresAuth: true },
  },
  {
    path: '/views/settings',
    name: 'Settings',
    component: SettingsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/views/calendar',
    name: 'Calendar',
    component: CalendarView,
    meta: { requiresAuth: true },
  },
  {
    path: '/views/fanuc-processor',
    name: 'FanucLadderProcessor',
    component: FanucLadderProcessor,
    meta: { requiresAuth: true},
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token');

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ path: '/not-authorized' });
  } else {
    next();
  }
});

export default router;
