<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Create New Title</span>
    </template>

    <template v-slot:content>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" class="pt-6 pb-1">
            <v-select
              :items="employeeDepartments"
              v-model="newTitle.departmentId"
              item-title="department"
              item-value="employee_Department_ID"
              variant="outlined"
              density="compact"
              label="Department"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-1 pt-1">

            <v-text-field
              :disabled="newTitle.departmentId === ''"
              v-model="newTitle.titleName"
              variant="outlined"
              density="compact"
              label="New Title Name"
              :rules="[rules.required, rules.counter ]"
              counter="40"
            ></v-text-field>

          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:footer>
      <v-btn variant="text" @click="closeDialog">Cancel</v-btn>
      <v-btn
        color="primary"
        append-icon="mdi-plus"
        variant="tonal"
        :loading="isAddBtnLoading"
        :disabled="!valid"
        @click="submitForm"
      >Add Title</v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api'
import DialogBase from './DialogBase.vue';

export default {
  name: 'AddEmployeeDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      isAddBtnLoading: false,
      shifts: [
        { title: '1st Shift', key: '1' },
        { title: '2nd Shift', key: '2' },
        { title: '3rd Shift', key: '3' },
      ],
      employeeTitles: [],
      employeeDepartments: [],
      newTitle: {
        titleName: '',
        departmentId: '',
      },
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 60 || 'Max characters reached',
      }
    }
  },
  mounted() {
    this.getEmployeeTitles();
    this.getEmployeeDepartments();
  },
  methods: {
    async getEmployeeTitles() {
      try {
        const response = await api.get('/Employee/GetEmployeeTitles');
        if (response.status === 200) {
          this.employeeTitles = response.data;
          return true;
        } else {
          console.log('Error fetching employee titles.');
          return false;
        }
      } catch (error) {
        console.error('API error:', error);
        return false;
      }
    },
    async getEmployeeDepartments() {
      try {
        const response = await api.get('/Employee/GetEmployeeDepartments');
        if (response.status === 200) {
          this.employeeDepartments = response.data;
          return true;
        } else {
          console.log('Error fetching employee departments.');
          return false;
        }
      } catch (error) {
        console.error('API error:', error);
        return false;
      }
    },
    closeDialog() {
      this.dialogOpen = false;
      this.resetForm();
    },
    async submitForm() {
      this.isAddBtnLoading = true;

      const title = {
        Department_ID: this.newTitle.departmentId,
        Title: this.newTitle.titleName,
      };

      try {
        await api.post('/Employee/AddEmployeeTitle', title);
        await new Promise(resolve => setTimeout(resolve, 500));

        this.showSnackbar('success', 'mdi-check-circle', 'Successfully Added Title!', 'Added ' + title.First_Name + ' to title list.');
        this.refreshParentCollection();
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Create Contact', 'An error occured while attempting to create new contact.');
      } finally {
        this.isAddBtnLoading = false;
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    refreshParentCollection() {
      this.$emit('refresh-employees');
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000,
      });
    },
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:isDialogOpen', value);
      }
    },
  }
}
</script>
