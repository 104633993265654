import { reactive } from 'vue';

const state = reactive({
  userProfile: null,
  hasAuthenticated: false,
});

//set the user profile and mark the user as authenticated
const setUserProfile = (profile) => {
  state.userProfile = profile;
  state.hasAuthenticated = true;
};

//clear the user profile and reset authentication status
const clearUserProfile = () => {
  state.userProfile = null;
  state.hasAuthenticated = false;
};

// Function to check if the user has a specific role
const hasRole = (role) => {
  return state.userProfile?.roles?.includes(role) || false;
};

export const useUserState = () => {
  return {
    state,
    setUserProfile,
    clearUserProfile,
    hasRole,
  };
};
