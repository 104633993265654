<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>{{taskDetails?.type || 'Loading...'}}</span>
    </template>

    <template v-slot:actions>
      <v-chip 
        class="ma-1 mr-2" 
        variant="tonal" 
        color="primary"
        label>
        <v-icon icon="mdi-label-outline" start></v-icon>
        {{ taskDetails?.user_Task_ID || 'Loading...' }}
      </v-chip>
    </template>

    <template v-slot:content>
      <v-row>
        <v-col cols="12" class="pt-4 pb-0">
          <p><strong>Task Information</strong></p>
        </v-col>

        <v-col cols="12">
          <v-card class="py-4" variant="flat" border="gray thin" elevation="0">
            <v-row v-for="(field, index) in fields" :key="index" class="px-4">
              <v-col class="text-left" cols="6">
                <strong>{{ field.label }}:</strong>
              </v-col>
              <v-col class="text-right" cols="6">
                {{ field.key === 'due_Date' || field.key === 'date_Created' ? formatDate(taskDetails?.[field.key]) : (taskDetails?.[field.key] === null || taskDetails?.[field.key] === "" ? 'Not Specified' : taskDetails?.[field.key]) }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template v-slot:footer>
      <v-btn text="Close" variant="text" @click="closeDialog">Close</v-btn>
    </template>
  </DialogBase>
</template>

<script>
import DialogBase from './DialogBase.vue';
import api from '@/services/api';

export default {
  name: 'TaskDetailsDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    targetTaskId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      taskDetails: null,
      loading: false, //API call is in progress
      fields: [
        { label: 'Description', key: 'description' },
        { label: 'Status', key: 'status' },
        { label: 'Assigned Count', key: 'assigned_Count' },
        { label: 'Task Creator', key: 'creator_Name' },
        { label: 'Due Date', key: 'due_Date' },
        { label: 'Date Created', key: 'date_Created' }
      ]
    };
  },
  methods: {
    async fetchTaskDetails() {
      if (!this.targetTaskId) return;
      this.loading = true;
      try {
        console.log(this.targetTaskId)
        const response = await api.get(`/UserTask/GetUserTaskById?id=${this.targetTaskId}`);
        this.taskDetails = response.data;
      } catch (error) {
        console.error('Error fetching task details:', error);
        this.taskDetails = null;
      } finally {
        this.loading = false;
      }
    },
    closeDialog() {
      this.dialogOpen = false;
      this.taskDetails = null; // Reset task details
    },
    formatDate(dateString) {
      if (!dateString) return 'Not Specified';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    }
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:is-dialog-open', value);
      }
    }
  },
  watch: {
    targetTaskId: {
      immediate: true,
      handler() {
        this.fetchTaskDetails();
      }
    }
  }
};
</script>

<style scoped>
.text-right {
  overflow-wrap: break-word;
}
</style>
