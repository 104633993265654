<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Create New Contact</span>
    </template>

    <template v-slot:content>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" class="pt-2">
            <p><strong>Personal Information</strong></p>
          </v-col>
          <v-col cols="6" class="pb-1 pt-1 pr-1">
            <v-text-field
              v-model="newContact.firstName"
              label="First Name"
              variant="outlined"
              density="compact"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-1 pt-1 pl-1">
            <v-text-field
              v-model="newContact.lastName"
              label="Last Name"
              variant="outlined"
              density="compact"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-1 pt-1 pr-1">
            <v-text-field
              v-model="newContact.title"
              label="Title"
              variant="outlined"
              density="compact"
              :rules="[rules.required, rules.counter]"
              maxlength="60"
              counter
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-1 pl-1 pt-1">
            <v-text-field
              v-model="newContact.company"
              label="Company"
              variant="outlined"
              density="compact"
              maxlength="60"
              counter
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-1 pb-2" >
            <v-select
              :items="contactTypes"
              v-model="newContact.typeID"
              item-title="type"
              item-value="type_ID"
              variant="outlined"
              density="compact"
              label="Contact Type"
              :rules="[rules.required]"
            ></v-select>
          </v-col>

        </v-row>

        <!-- Contact Information -->
        <v-row>
          <v-col cols="12" class="pt-0">
            <p><strong>Contact Information</strong></p>
          </v-col>
          <v-col cols="6" class="pb-2 pt-1 pr-1">
            <v-text-field
              v-model="newContact.phoneMain"
              label="Phone Main"
              variant="outlined"
              density="compact"
              :rules="[rules.phone]"
              placeholder="123-456-7890"
              v-mask="'###-###-#### x#####'"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-2 pt-1 pl-1">
            <v-text-field
              v-model="newContact.phoneDirect"
              label="Phone Direct"
              variant="outlined"
              density="compact"
              :rules="[rules.phone]"
              placeholder="123-456-7890"
              v-mask="'###-###-#### x#####'"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-2 pt-1 pr-1">
            <v-text-field
              v-model="newContact.phoneMobile"
              label="Phone Mobile"
              variant="outlined"
              density="compact"
              :rules="[rules.phone]"
              placeholder="123-456-7890"
              v-mask="'###-###-#### x#####'"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-2 pt-1 pl-1">
            <v-text-field
              v-model="newContact.email"
              label="Email"
              variant="outlined"
              density="compact"
              :rules="[rules.email]"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Address Information -->
        <v-row>
          <v-col cols="12" class="pb-2 pt-1">
            <p><strong>Address Information</strong></p>
          </v-col>
          <v-col cols="12" class="pb-2 pt-1">
            <v-text-field
              v-model="newContact.address"
              label="Address"
              variant="outlined"
              density="compact"
              maxlength="120"
              counter
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-2 pt-1 pr-1">
            <v-text-field
              v-model="newContact.city"
              label="City"
              variant="outlined"
              density="compact"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-2 pt-1 pl-1">
            <v-text-field
              v-model="newContact.state"
              label="State"
              variant="outlined"
              density="compact"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-2 pt-1">
            <v-text-field
              v-model="newContact.zip"
              label="ZIP Code"
              variant="outlined"
              density="compact"
              type="text"
              maxlength="5"
              :rules="[rules.zip]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:footer>
      <v-btn variant="text" @click="closeDialog">Cancel</v-btn>
      <v-btn
        color="primary"
        append-icon="mdi-plus"
        variant="tonal"
        :loading="isAddBtnLoading"
        :disabled="!valid"
        @click="submitForm"
      >Add Contact</v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api'
import DialogBase from './DialogBase.vue';

export default {
  name: 'AddContactDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      isAddBtnLoading: false,
      contactTypes: [],
      newContact: {
        typeID: '',
        firstName: '',
        lastName: '',
        phoneMain: '',
        phoneDirect: '',
        phoneMobile: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        title: '',
        company: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 60 || 'Max characters reached',
        email: value => !value || /.+@.+\..+/.test(value) || 'E-mail must be valid.',
        phone: value => !value || /^(\d{3}-\d{3}-\d{4}( x\d{1,5})?)?$/.test(value) || 'Invalid (e.g., 123-456-7890 x1234).',
        zip: value => !value || /^\d{5}$/.test(value) || 'ZIP Code must be 5 digits.'
      }
    }
  },
  mounted() {
    this.getContactTypes();
  },
  methods: {
    async getContactTypes() {
      try {
        const response = await api.get('/Contact/GetContactTypes');
        if (response.status === 200) {
          this.contactTypes = response.data;
        } else {
          console.log('internal error');
        }
      } catch (error) {
        console.error(error);
      }
    },
    closeDialog() {
      this.dialogOpen = false;
      this.resetForm();
    },
    async submitForm() {
      this.isAddBtnLoading = true;

      const contact = {
        Type_ID: this.newContact.typeID,
        First_Name: this.newContact.firstName,
        Last_Name: this.newContact.lastName,
        Phone_Primary: this.newContact.phoneMain,
        Phone_Direct: this.newContact.phoneDirect,
        Phone_Mobile: this.newContact.phoneMobile,
        Email: this.newContact.email,
        Address: this.newContact.address,
        City: this.newContact.city,
        State: this.newContact.state,
        Zip_Code: this.newContact.zip,
        Title: this.newContact.title,
        Company_Name: this.newContact.company
      };

      try {
        await api.post('/Contact/AddContact', contact);
        await new Promise(resolve => setTimeout(resolve, 500));

        this.showSnackbar('success', 'mdi-check-circle', 'Successfully Created Contact!', 'Added ' + contact.First_Name + ' ' + contact.Last_Name + ' to contacts.');
        this.refreshParentCollection();
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Create Contact', 'An error occured while attempting to create new contact.');
      } finally {
        this.isAddBtnLoading = false;
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    refreshParentCollection() {
      this.$emit('refresh-contacts');
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000,
      });
    },
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:isDialogOpen', value);
      }
    }
  }
}
</script>
