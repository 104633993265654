<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Create New Task</span>
    </template>

    <template v-slot:actions>
      <v-tooltip location="left" text="Exit without saving">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            class="ma-1 mr-2"
            icon="mdi-close"
            variant="tonal"
            color="gray"
            size="small"
            @click="closeDialog"
          >
          </v-btn>
        </template>
      </v-tooltip>
    </template>

    <template v-slot:content>
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Stepper Control -->
        <v-stepper non-linear flat class="pa-1" v-model="step">
          <v-stepper-header height="10">
            <v-stepper-item title="Task Info" value="0" :complete="isStep1Complete"></v-stepper-item>
            <v-divider></v-divider>
            <v-stepper-item title="Parent Task" value="1" :complete="step > 1" :disabled="!isStep1Complete"></v-stepper-item>
            <v-divider></v-divider>
            <v-stepper-item title="Assign" value="2" :disabled="step < 1"></v-stepper-item>
          </v-stepper-header>

          <!-- Stepper Window Content -->
          <v-stepper-window class="pa-0 ma-0">
            <!-- Task Details Step -->
            <v-stepper-window-item :value="0">
              <v-card class="ma-0 pa-2">
                <v-select
                  :items="taskTypes"
                  v-model="newTask.type_ID"
                  item-title="type"
                  item-value="type_ID"
                  variant="outlined"
                  density="compact"
                  label="Task Type"
                  :rules="[rules.required]"
                ></v-select>

                <v-textarea
                  class="pb-2"
                  variant="outlined"
                  density="compact"
                  v-model="newTask.description"
                  label="Task Description"
                  :rules="[rules.required]"
                ></v-textarea>

                <v-text-field
                  variant="outlined"
                  density="compact"
                  v-model="newTask.due_Date"
                  label="Due Date"
                  type="date"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-card>
            </v-stepper-window-item>

            <!-- Parent Task Step -->
            <v-stepper-window-item :value="1">
              <v-card class="ma-0 pa-0">
                <v-col cols="12" class="px-0 pt-2 ma-0">
                  <v-autocomplete
                    prepend-inner-icon="mdi-magnify"
                    :items="tasks"
                    item-title="description"
                    item-value="user_Task_ID"
                    v-model="selectedTask"
                    variant="outlined"
                    density="compact"
                    clearable
                    hide-details
                    return-object
                  >
                    <template v-slot:item="{ props, item }">
                      <v-list-item v-bind="props">
                        <template v-slot:prepend>
                          <v-avatar variant="tonal" size="36" color="primary">
                            <v-icon size="16">{{ item.raw.type_Icon }}</v-icon>
                          </v-avatar>
                        </template>
                        <template v-slot:title>
                          <div>{{ item.raw.description }}</div>
                        </template>
                        <template v-slot:subtitle>
                          <div>{{ item.raw.type }}</div>
                        </template>
                        <template v-slot:append>
                          <v-btn
                            class="ml-2"
                            size="small"
                            density="comfortable"
                            icon="mdi-help"
                            variant="tonal"
                            color="primary"
                            @click="toggleTaskInformationDialog(item)"
                          ></v-btn>
                        </template>
                      </v-list-item>
                    </template>
                  </v-autocomplete>


                </v-col>

                <v-alert
                  v-if="selectedTask"
                  type="success"
                  text
                  variant="tonal"
                  border="left"
                  class="mt-2"
                >
                  Selected Parent Task: {{ selectedTask.description }}
                </v-alert>

                <v-alert
                  v-else
                  type="info"
                  text
                  variant="tonal"
                  color="grey lighten-1"
                  border="left"
                  class="mt-2"
                >
                  No parent task selected. This step is not required.
                </v-alert>
              </v-card>
            </v-stepper-window-item>

            <!-- Employees Step the-->
            <v-stepper-window-item :value="2">
              <v-card>
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" class="px-0 pt-2 ma-0">
                    <!-- Employee Search -->
                    <v-autocomplete
                      v-model="selectedEmployee"
                      :items="employees"
                      item-title="full_Name"
                      item-value="employee_ID"
                      label="Find Employee"
                      density="compact"
                      variant="outlined"
                      clearable
                      hide-details
                      @update:model-value="addEmployeeToSelected"
                    >
                      <template v-slot:item="{ props, item }">
                        <v-list-item
                          v-bind="props"
                          :prepend-avatar="getInitials(item.raw.full_Name)"
                          :subtitle="item.raw.department ?? 'Unknown'"
                        >
                          <template v-slot:prepend>
                            <v-avatar variant="tonal" size="40" color="primary">
                              <p>{{ getInitials(item.raw.full_Name) }}</p>
                            </v-avatar>
                          </template>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <!-- Display Selected Employees -->
                  <v-col cols="12" v-if="selectedEmployees.length > 0">
                    <div class="py-2 font-weight-bold">Selected Employees</div>
                    <v-divider class="pt-2"></v-divider>

                    <v-scroll-y-transition group hide-on-leave>
                      <v-chip
                        v-for="(employee, index) in selectedEmployees"
                        :key="employee.employee_ID"
                        :text="employee.full_Name"
                        class="ma-1"
                        color="primary"
                        prepend-icon="mdi-account"
                        size="small"
                        @click:close="removeEmployee(index)"
                        closable
                      ></v-chip>
                    </v-scroll-y-transition>
                  </v-col>

                  <v-col cols="12" v-else ></v-col>
                </v-row>
              </v-card>
            </v-stepper-window-item>

          </v-stepper-window>
        </v-stepper>
      </v-form>
    </template>

    <!-- Footer with dynamic button for Next/Add Task -->
    <template v-slot:footer>
      <v-btn variant="text" @click="lastStep" :disabled="step < 1">Back</v-btn>
      <v-btn
        v-if="step === 2"
        color="primary"
        append-icon="mdi-plus"
        variant="tonal"
        :loading="isAddBtnLoading"
        :disabled="isAddBtnDisabled"
        @click="addTask"
      >
        Create Task
      </v-btn>
      <v-btn
        v-else
        color="primary"
        append-icon="mdi-arrow-right"
        variant="tonal"
        :disabled="!isStep1Complete"
        @click="nextStep"
      >
        Next Step
      </v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api';
import DialogBase from './DialogBase.vue';

export default {
  name: 'AddTaskDialog',
  components: {
    DialogBase,
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAddBtnLoading: false,
      valid: true,
      step: 0,
      newTask: {
        type_ID: null,
        description: '',
        due_Date: null,
      },
      taskTypes: [],
      tasks: [],
      selectedTask: null,
      employees: [],
      searchEmployee: '',
      selectedEmployees: [],
      isTasksLoading: false,
      isEmployeesLoading: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  computed: {
    isStep1Complete() {
      return this.newTask.type_ID && this.newTask.description.length >= 3 && this.newTask.due_Date;
    },
    isAddBtnDisabled() {
      return !this.isStep1Complete;
    },
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:isDialogOpen', value);
      },
    },
  },
  watch: {
    step(newStep) {
      if (newStep === 1) {
        this.getTasks();
      } else if (newStep === 2) {
        this.getEmployees();
      }
    },
  },
  async mounted() {
    await this.getTaskTypes();
  },
  methods: {
    async getTasks() {
      if (this.tasks.length) return;
      this.isTasksLoading = true;
      try {
        const response = await api.get(`/UserTask/GetAllOpenUserTasks`);
        if (response.status === 200) {
          this.tasks = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isTasksLoading = false;
      }
    },
    getInitials(name) {
      if (!name) return '';
      return name
        .split(' ')
        .map((n) => n.charAt(0))
        .join('')
        .toUpperCase();
    },
    addEmployeeToSelected(employeeId) {
      const selected = this.employees.find((emp) => emp.employee_ID === employeeId);
      if (selected && !this.selectedEmployees.some((emp) => emp.employee_ID === selected.employee_ID)) {
        this.selectedEmployees.push(selected);
      }
      this.selectedEmployee = null;
    },
    removeEmployee(index) {
      this.selectedEmployees.splice(index, 1);
    },
    async getTaskTypes() {
      try {
        const response = await api.get(`/TaskType/GetTaskTypes`);
        if (response.status === 200) {
          this.taskTypes = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getEmployees() {
      if (this.employees.length) return;
      this.isEmployeesLoading = true;
      try {
        const response = await api.get(`/Employee/GetEmployees`);
        if (response.status === 200) {
          this.employees = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isEmployeesLoading = false;
      }
    },
    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.newTask.description = '';
      this.newTask.type_ID = null;
      this.newTask.due_Date = null;
      this.selectedTask = null;
      this.selectedEmployees = [];
      this.searchEmployee = '';
      this.step = 0;
    },
    closeDialog() {
      this.dialogOpen = false;
      this.resetForm();
    },
    nextStep() {
      if (this.isStep1Complete && this.step < 2) {
        this.step++;
      }
    },
    lastStep() {
      if (this.step > 0) {
        this.step--;
      }
    },
    async addTask() {
      this.isAddBtnLoading = true;
      const userTask = {
        Type_ID: this.newTask.type_ID,
        Description: this.newTask.description,
        Due_Date: this.newTask.due_Date,
        Parent_Task_ID: this.selectedTask ? this.selectedTask.user_Task_ID : null,
        Assigned_Employee_IDs: this.selectedEmployees.map((employee) => employee.employee_ID),
      };
      try {
        await api.post('/UserTask/AddUserTask', userTask);
        this.showSnackbar('success', 'mdi-check-circle', 'Task Created!', 'New task created successfully.');
        this.$emit('refresh-tasks');
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Error', 'Failed to create task.');
      } finally {
        this.isAddBtnLoading = false;
      }
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000,
      });
    },
  },
};
</script>

<style scoped>
.scrollable-treeview {
  max-height: 300px;
  overflow-y: auto;
}

.v-stepper-header {
  box-shadow: 0px 0px 0px 0px !important;
  height: 50px;
  align-items: center;
  display: flex;
  position: relative;
  overflow-x: auto;
  justify-content: space-between;
  z-index: 1;
}

</style>
