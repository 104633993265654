<template>
  <v-row dense class="pa-1">
    <v-col class="pa-0" cols="12" md="4">
      <v-card 
        border="gray thin"
        elevation="0" 
        class="ma-1 pa-3" 
        height="100"
        :title="taskStatusData.notStarted"
        subtitle="Not started tasks"
      >
        <template v-slot:prepend>
          <v-btn
            :to="getTaskNavigationPath('notStarted')"
            size="50"
            color="text"
            variant="tonal"
            prepend-icon="mdi-clock-outline"
            class="status-btn mr-2"
          ></v-btn>
        </template>

        <template v-slot:append>
          <v-btn
            :to="getTaskNavigationPath('notStarted')"
            :disabled="taskStatusData.notStarted < 1"
            color="text"
            text="View"
            variant="text"
          ></v-btn>
        </template>
      </v-card>
    </v-col>  

    <v-col class="pa-0" cols="12" md="4">
      <v-card 
        border="gray thin" 
        elevation="0" 
        class="ma-1 pa-3" 
        height="100"
        :title="taskStatusData.inProgress"
        subtitle="In progress tasks"
      >
        <template v-slot:prepend>
          <v-btn
            :to="getTaskNavigationPath('inProgress')"
            size="50"
            color="orange-darken-1"
            variant="tonal"
            prepend-icon="mdi-timer-sand"
            class="status-btn mr-2"
          ></v-btn>
        </template>

        <template v-slot:append>
          <v-btn
            :to="getTaskNavigationPath('inProgress')"
            :disabled="taskStatusData.inProgress < 1"
            color="orange-darken-1"
            text="View"
            variant="text"
          ></v-btn>
        </template>
      </v-card>
    </v-col>  

    <v-col class="pa-0" cols="12" md="4">
      <v-card 
        border="gray thin" 
        elevation="0" 
        class="ma-1 pa-3" 
        height="100"    
        :title="taskStatusData.pastDue" 
        subtitle="Overdue tasks"
      >
        <template v-slot:prepend>
          <v-btn
            :to="getTaskNavigationPath('pastDue')" 
            size="50"
            color="red-lighten-2"
            variant="tonal"
            prepend-icon="mdi-alert-outline"
            class="status-btn mr-2"
          ></v-btn>
        </template>

        <template v-slot:append>
          <v-btn
            :to="getTaskNavigationPath('pastDue')"
            :disabled="taskStatusData.pastDue < 1"
            color="red-lighten-2"
            text="View"
            variant="text"
          ></v-btn>
        </template>
      </v-card>
    </v-col>  
  </v-row>  
</template>

<script>
import { ref, onMounted } from 'vue';
import api from '@/services/api';

export default {
  name: 'TaskStatusCluster',
  setup() {
    const taskStatusData = ref({
      notStarted: 0,
      inProgress: 0,
      pastDue: 0
    });

    const getTaskNavigationPath = (status) => {
      return `/views/my-tasks?status=${status}`;
    };

    const fetchTaskStatusData = async () => {
      try {
        const response = await api.get('/dashboard/GetTaskStatusCountByEmployeeId');
        if (response && response.data) {
          taskStatusData.value = response.data;
        } else {
          console.error('Invalid response data');
        }
      } catch (err) {
        console.error('Error fetching task status data:', err);
      }
    };

    onMounted(() => {
      fetchTaskStatusData();
    });

    return {
      taskStatusData,
      getTaskNavigationPath
    };
  }
}
</script>

<style scoped>
.v-alert {
  padding: 8px;
}

.status-btn {
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

.status-btn:hover {
  transform: scale(1.2);
  color: var(--v-primary-base); 
}

.v-card {
  cursor: pointer;
}
</style>
