<template>
  <div>
    <v-dialog
      persistent
      v-model="dialogVisible"
      :width="dialogWidth"
    > <!-- transition="dialog-bottom-transition" -->
      <div>
        <v-card class="pa-3" v-if="!cleanFormat">

          <v-card-title class="ml-3 mt-2 pb-2">
            <v-row>
              <slot name="header"></slot>
              <v-spacer></v-spacer>
              <slot name="actions"></slot>
            </v-row>
          </v-card-title>

          <v-card-subtitle>
            <slot name="subHeader"></slot>
          </v-card-subtitle>

          <v-card-text>
            <slot name="content"></slot>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <slot name="footer"></slot>
          </v-card-actions>

        </v-card>
        <slot v-if="cleanFormat" name="content"></slot>
      </div>  
    </v-dialog>
    <slot name="childDialog"></slot>
  </div>
</template>

<script>
export default {
  props: {
    isDialogVisible: {
      type: Boolean,
      required: true
    },
    dialogWidth: {
      type: String,
      default: '600px'
    },
    cleanFormat: {
      type: Boolean,
      required: false
    },
    cardClasses: {
      type: [String, Array, Object],
      default: () => ['pa-5']
    },
    headerClasses: {
      type: [String, Array, Object],
      default: () => ['mr-10', 'pr-10']
    },
      closeConditional: Function
  },
  data() {
    return {
      dialogVisible: this.isDialogVisible
    };
  },
  watch: {
    isDialogVisible(newValue) {
      this.dialogVisible = newValue;
    }
  },
  methods: {
    closeMainDialog() {
      if (this.closeConditional && this.closeConditional()) {
        this.$emit('closeDialog');
      }
    }
  }

};
</script>
