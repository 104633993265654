<template>
  <div class="d-flex justify-center align-center" style="height: 100vh; padding: 16px;">
    <v-col cols="12" md="8" lg="6" class="text-center">
      <v-row class="justify-center align-center">
        <v-img :max-width="imageMaxWidth" src="@/assets/app/construction.png"></v-img>
      </v-row>

      <v-row class="text-h5 mt-6 justify-center align-center">
        <p>We're working hard to bring this site online soon!</p>
      </v-row>

      <v-row class="body-2 mt-8 justify-center align-center">
        <p>Thank you for your patience. If you need assistance,</p>
      </v-row>

      <v-row class="body-2 justify-center align-center">
        <p>please contact the IT department.</p>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageMaxWidth: 100, // You can adjust this for smaller screens if needed
    };
  },
  created() {
    // Adjust image size dynamically based on the window size
    window.addEventListener('resize', this.adjustImageSize);
    this.adjustImageSize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustImageSize);
  },
  methods: {
    adjustImageSize() {
      if (window.innerWidth < 600) {
        this.imageMaxWidth = 80; // Smaller image size for small screens
      } else {
        this.imageMaxWidth = 100; // Default size
      }
    }
  }
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}
</style>
