<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Add Employees to Task</span>
    </template>

    <template v-slot:content>
      <v-container class="pt-1 px-0">
        <!-- Autocomplete to add employee to task-->
        <v-autocomplete
          v-model="selectedEmployee"
          :disabled="isUpdating"
          :items="allEmployees"
          item-title="full_Name"
          item-value="employee_ID"
          label="Find Employee"
          density="comfortable"
          variant="outlined"
          @update:model-value="addEmployee"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :prepend-avatar="getInitials(item.raw.full_Name)"
              :subtitle="item.raw.department ?? 'Unknown'"
            >
              <template v-slot:prepend>
                <v-avatar variant="tonal" size="40" color="primary">
                  <p>{{ getInitials(item.raw.full_Name) }}</p>
                </v-avatar>
              </template>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-empty-state v-if="currentlyAddedEmployees.length < 1"
          icon="mdi-magnify"
          text="No employees have been added yet..."
        ></v-empty-state>

        <!-- List of added employees with subtle fade/slide animation -->
        <v-row class="mt-1" v-else>
          <v-col class="py-0" cols="12">
            <div class="d-flex">
              <h4>Currently Added</h4>
              <v-spacer></v-spacer>
              <v-chip size="small" label color="primary" variant="tonal">
                {{ currentlyAddedEmployees.length }} 
                <span class="ml-1" v-if="currentlyAddedEmployees.length === 1">Employee</span> 
                <span class="ml-1" v-else> Employees</span>
              </v-chip>
            </div>

            <v-divider class="my-2"></v-divider>

            <v-list lines="two" max-height="200" class="pa-0 scrollable-list">
              <v-list-item
                class="py-0 animate-add-remove"
                v-for="employee in currentlyAddedEmployees"
                :key="employee.employee_ID"
                :subtitle="employee.department ?? 'Unknown'"
                :title="employee.full_Name"
              >
                <template v-slot:prepend>
                  <v-avatar variant="tonal" size="40" color="primary">
                    <v-icon>mdi-account</v-icon>
                  </v-avatar>
                </template>

                <template v-slot:append>
                  <!-- Chip indicating newly added employee -->
                  <v-chip
                    v-if="newlyAddedEmployees.some(newEmp => newEmp.employee_ID === employee.employee_ID)"
                    prepend-icon="mdi-account-plus"
                    text="New"
                    size="x-small"
                    label
                    color="secondary"
                    variant="tonal"
                  ></v-chip>

                  <v-btn
                    class="ml-2"
                    color="grey-lighten-1"
                    icon="mdi-close"
                    variant="text"
                    @click="removeEmployee(employee)"
                  ></v-btn>
                </template>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- Save changes button -->
    <template v-slot:footer>
      <v-btn variant="text" @click="closeDialog">Cancel</v-btn>
      <v-btn
        color="primary"
        append-icon="mdi-content-save"
        variant="tonal"
        :loading="isAddBtnLoading"
        :disabled="isAddBtnDisabled"
        @click="saveChanges"
      >
        Save Changes
      </v-btn>
    </template>
  </DialogBase>
</template>
<script>
import api from '@/services/api';
import DialogBase from './DialogBase.vue';

export default {
  name: 'AddEmployeesToTaskDialog',
  components: {
    DialogBase,
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    targetTask: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isEmployeesLoading: false,
      selectedEmployee: null,
      allEmployees: [],
      currentlyAddedEmployees: [],
      newlyAddedEmployees: [],
      removedEmployees: [], // Track removed employees for API
      isAddBtnLoading: false,
    };
  },
  async mounted() {
    this.isEmployeesLoading = true;
    await this.getEmployees();
    await this.getCurrentlyAddedEmployees();
    this.isEmployeesLoading = false;
  },
  methods: {
    async getEmployees() {
      this.isEmployeesLoading = true;
      try {
        const response = await api.get(`/Employee/GetEmployees`);
        if (response.status === 200) {
          this.allEmployees = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      this.isEmployeesLoading = false;
    },
    async getCurrentlyAddedEmployees() {
      try {
        const response = await api.get(`/Employee/GetEmployeesAssignedToTask?taskId=${this.targetTask.user_Task_ID}`);
        if (response.status === 200) {
          this.currentlyAddedEmployees = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getInitials(name) {
      if (!name) return '';
      return name.split(' ').map((n) => n.charAt(0)).join('').toUpperCase();
    },
    closeDialog() {
      this.$emit('update:isDialogOpen', false);
    },
    addEmployee(employeeId) {
      const employee = this.allEmployees.find((emp) => emp.employee_ID === employeeId);
      if (employee && !this.currentlyAddedEmployees.find(emp => emp.employee_ID === employee.employee_ID)) {
        this.currentlyAddedEmployees.unshift(employee);
        this.newlyAddedEmployees.push(employee);
      }
      this.selectedEmployee = null;
    },
    async saveChanges() {
      this.isAddBtnLoading = true;
      try {
        for (const employee of this.newlyAddedEmployees) {
          await api.post(`/Employee/AddEmployeeToUserTask`, null, {
            params: {
              employeeId: employee.employee_ID,
              taskId: this.targetTask.user_Task_ID,
            },
          });
        }
        for (const employee of this.removedEmployees) {
          await api.delete(`/Employee/RemoveEmployeeFromUserTask`, {
            params: {
              employeeId: employee.employee_ID,
              taskId: this.targetTask.user_Task_ID,
            },
          });
        }
        this.showSnackbar(
          'success',
          'mdi-check-bold',
          'Successfully Saved Changes!',
          `${this.newlyAddedEmployees.length} employees added, ${this.removedEmployees.length} removed.`
        );
        this.newlyAddedEmployees = [];
        this.removedEmployees = [];
        this.$emit('refresh-tasks');
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert', 'Error', 'Failed to save changes. Please try again.');
      } finally {
        this.isAddBtnLoading = false;
      }
    },
    removeEmployee(employee) {
      this.currentlyAddedEmployees = this.currentlyAddedEmployees.filter(
        (emp) => emp.employee_ID !== employee.employee_ID
      );
      this.newlyAddedEmployees = this.newlyAddedEmployees.filter(
        (emp) => emp.employee_ID !== employee.employee_ID
      );
      this.removedEmployees.push(employee);
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000,
      });
    },
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:isDialogOpen', value);
      },
    },
    isAddBtnDisabled() {
      return this.newlyAddedEmployees.length === 0 && this.removedEmployees.length === 0;
    },
  },
};
</script>
