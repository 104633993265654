<template>
  <div>
    <v-navigation-drawer
      class="no-scrollbar"
      :width="rail ? 80 : 290"
      v-model="drawer"
      :rail="rail"
      nav
      @update:model="onDrawerUpdate"
    >
      <v-list>
        <v-skeleton-loader
          class="pr-2"
          :loading="!userProfile?.displayName"
          boilerplate 
          type="list-item-avatar"
        >
          <v-list-item
            :subtitle="userProfile?.displayName"
            title="Global Precision Group"
            @click="toggleRail"
          >
            <template v-slot:prepend>
              <v-avatar color="primary">
                <span :class="rail ? 'text-caption' : 'text-h6'">{{ initials }}</span>
              </v-avatar>
            </template>
          </v-list-item>
        </v-skeleton-loader>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <!-- User Navigation Items -->
        <v-list-item
          color="primary"
          value="dashboard"
          prepend-icon="mdi-chart-areaspline"
          @click="handleClick('/views/dashboard')"
          class="nav-item-hover"
        >
          <span v-if="!rail">
            Dashboard
          </span>
        </v-list-item>

        <v-list-item
          color="primary"
          value="my-tasks"
          prepend-icon="mdi-bell-check-outline"
          @click="handleClick('/views/my-tasks')"
          class="nav-item-hover"
        >
          <template v-slot:append>
            <v-badge
              :color="badgeColor"
              :content="badgeContent"
              class="animated-badge"
              :icon="badgeContent < 1 ? 'mdi-check' : ''"
              inline
            />
          </template>
          <!-- Prevents the text from collapsing into two rows when the rail isnt visible -->
          <span class="text-no-wrap" v-if="!rail">
            My Tasks
          </span>
        </v-list-item>

        <v-divider class="my-1"></v-divider>

        <!-- Navigation for Higher Privilege Users -->
        <v-list-item
          color="primary"
          v-if="hasRole('Task.Administrator') || hasRole('Task.User')"
          value="employees"
          prepend-icon="mdi-account-search-outline"
          @click="handleClick('/views/employees')"
          class="nav-item-hover"
        >
        <span v-if="!rail">Employees</span>
        </v-list-item>

        <v-list-item
          color="primary"
          v-if="hasRole('Task.Administrator')"
          value="contacts"
          prepend-icon="mdi-card-account-details-outline"
          @click="handleClick('/views/contacts')"
          class="nav-item-hover"
        >
        <span v-if="!rail">Contacts</span>
        </v-list-item>

        <v-list-item
          color="primary"
          v-if="hasRole('Task.Administrator')"
          value="task-management"
          prepend-icon="mdi-calendar-check"
          @click="handleClick('/views/task-management')"
          class="nav-item-hover"
        >
          <span class="text-no-wrap"  v-if="!rail">
            Task Management
          </span>
        </v-list-item>

        <v-list-item
          color="primary"
          v-if="hasRole('Task.Administrator') || hasRole('Task.User')"
          value="calendar"
          prepend-icon="mdi-calendar-month-outline"
          @click="handleClick('/views/calendar')"
          class="nav-item-hover"
        >
        <span v-if="!rail">Calendar</span>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list density="compact" nav>
          <v-list-item
            v-if="rail && (hasRole('Task.Administrator') || hasRole('Task.User'))"
            @click="handleClick('/views/settings')"
          >
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item>
          <v-btn
            v-else-if="!rail && (hasRole('Task.Administrator') || hasRole('Task.User'))"
            @click="handleClick('/views/settings')"
            prepend-icon="mdi-cog-outline"
            variant="text"
            block
          >
            Settings
          </v-btn>
        </v-list>
      </template>
    </v-navigation-drawer>

    <!-- Bottom Navigation for Mobile -->
    <v-bottom-navigation v-if="!drawer" grow>
      <v-btn value="dashboard" @click="handleClick('/views/dashboard')" icon="mdi-chart-areaspline"></v-btn>
      <v-btn value="my-tasks" @click="handleClick('/views/my-tasks')" icon="mdi-bell-check-outline"></v-btn>
      <v-btn
        v-if="hasRole('Task.Administrator') || hasRole('Task.User')"
        value="contacts"
        @click="handleClick('/views/contacts')"
        icon="mdi-card-account-details-outline"
      ></v-btn>
      <v-btn
        v-if="hasRole('Task.Administrator') || hasRole('Task.User')"
        value="employees"
        @click="handleClick('/views/employees')"
        icon="mdi-account-search-outline"
      ></v-btn>
      <v-btn
        v-if="hasRole('Task.Administrator')"
        value="task-management"
        @click="handleClick('/views/task-management')"
        icon="mdi-calendar-check"
      ></v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { useUserState } from '@/services/userStore';

export default {
  name: 'NavigationPanel',
  props: {
    uncompletedTaskCount: Number,
  },
  data() {
    return {
      drawer: true,
      rail: false,
      userState: useUserState(),
    };
  },
  computed: {
    userProfile() {
      return this.userState.state.userProfile;
    },
    initials() {
      if (this.userProfile?.displayName) {
        const [firstName, ...rest] = this.userProfile.displayName.split(' ');
        return firstName[0] + (rest.length ? rest[rest.length - 1][0] : '');
      }
      return '';
    },
    badgeContent() {
      return this.uncompletedTaskCount > 0 ? this.uncompletedTaskCount : '';
    },
    badgeColor() {
      return this.uncompletedTaskCount > 0 ? 'primary' : 'green';
    },
  },
  methods: {
    handleClick(route) {
      this.$router.push(route);
    },
    toggleRail() {
      this.rail = !this.rail;
    },
    hasRole(role) {
      return this.userState.hasRole(role);
    },
  },
};
</script>

<style>
.nav-item-hover .v-list-item__content {
  margin-left: -10px; /* Initial closer text-to-icon spacing */
  transition: transform 0.4s ease, margin-left 0.3s ease; /*transition */
}

.nav-item-hover:hover .v-list-item__content {
  margin-left: 5px;
  transform: translateX(-6px); /* slide effect */
}

.animated-badge {
  transition: transform 0.4s ease;
}

.badge-bounce {
  animation: bounce 0.3s ease;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

</style>
